<template>
  <v-row justify="center" align="center">
    <v-col align="center">
      <v-img
        src="android-chrome-192x192.png"
        aspect-ratio="1"
        height="100px"
        width="100px"
      />
      <p class="mt-4">
        Hola {{ this.$store.getters.usuario.name }}, bienvenido a Estudio APP.
        Por el momento nos encontramos en fase beta para administradores de
        dominio, en caso de que desees ser parte del proceso de pruebas, te
        pedimos que nos compartas los siguientes datos:
      </p>
      <form>
        <v-text-field
          v-model="centro_educativo"
          :error-messages="centroEductativoErrors"
          label="Nombre de tu centro educativo*"
          required
          @input="$v.centro_educativo.$touch()"
          @blur="$v.centro_educativo.$touch()"
        ></v-text-field>
        <v-text-field
          v-model="rol"
          :error-messages="rolErrors"
          label="Rol que desempeñas*"
          required
          @input="$v.rol.$touch()"
          @blur="$v.rol.$touch()"
        ></v-text-field>
        <v-text-field
          v-model="dominio"
          :error-messages="dominioErrors"
          label="Dominio de GSuite for Education*"
          required
          @input="$v.dominio.$touch()"
          @blur="$v.dominio.$touch()"
        ></v-text-field>
        <v-select
          v-model="administrador"
          :items="items"
          :error-messages="administradorErrors"
          label="¿Eres administrador de dominio?*"
          required
          @change="$v.administrador.$touch()"
          @blur="$v.administrador.$touch()"
        ></v-select>
        <a
          href="https://docs.google.com/document/d/e/2PACX-1vRu6fDqnBl_Wb9-EDUrYakeOYePROb_MHpJ4u444Rp_6nwt6-0vFQZ6h6L-IWAb3WOY3WB2mLvAsG6u/pub"
          target="_blank"
          >Aviso de Privacidad</a
        >
        <v-checkbox
          v-model="acepto"
          :error-messages="aceptoErrors"
          label="He leído el aviso de privacidad*"
          required
          @change="$v.acepto.$touch()"
          @blur="$v.acepto.$touch()"
        ></v-checkbox>
        <v-btn
          class="mr-4"
          dark
          color="orange"
          @click="submit"
          :disabled="loading"
          >Enviar solicitud</v-btn
        >
      </form>
      <v-overlay absolute opacity="1" :value="solicitado" color="white">
        <v-img
          src="android-chrome-192x192.png"
          aspect-ratio="1"
          height="100px"
          width="100px"
        />
        <p class="mt-4 black--text">
          Tu solicitud ha sido registrada, se analizará y pronto un miembro de
          nuestro equipo se pondrá en contacto contigo para informarte si fue
          aceptada y darte nuevas indicaciones. Muchas gracias ¡Al siguiente
          Nivel Educativo!
        </p>
        <!--<v-btn color="orange lighten-2" @click="solicitado = false">
          Hide Overlay
        </v-btn>-->
      </v-overlay>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios'
//axios.defaults.withCredentials = true;
//axios.defaults.baseURL = 'http://localhost:8000/'
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "Solicitud",
  mixins: [validationMixin],
  validations: {
    centro_educativo: { required, maxLength: maxLength(255) },
    rol: { required, maxLength: maxLength(255) },
    dominio: { required, maxLength: maxLength(255) },
    administrador: { required },
    acepto: {
      checked(val) {
        return val;
      },
    },
  },
  data() {
    return {
      solicitado: true,
      loading: false,
      /*
      url: process.env.VUE_APP_URL,
      title: process.env.VUE_APP_TITLE
      */
      centro_educativo: "",
      rol: "",
      dominio: "",
      administrador: null,
      items: ["Si", "No"],
      acepto: false,
    };
  },
  computed: {
    aceptoErrors() {
      const errors = [];
      if (!this.$v.acepto.$dirty) return errors;
      !this.$v.acepto.checked &&
        errors.push("Debes de haber leído el aviso de privacidad");
      return errors;
    },
    administradorErrors() {
      const errors = [];
      if (!this.$v.administrador.$dirty) return errors;
      !this.$v.administrador.required && errors.push("Respuesta requerida");
      return errors;
    },
    centroEductativoErrors() {
      const errors = [];
      if (!this.$v.centro_educativo.$dirty) return errors;
      !this.$v.centro_educativo.maxLength &&
        errors.push(
          "Nombre de centro educativo debe de ser menor a 255 caracteres de longitud"
        );
      !this.$v.centro_educativo.required &&
        errors.push("Nombre de centro educativo es requerido.");
      return errors;
    },
    rolErrors() {
      const errors = [];
      if (!this.$v.rol.$dirty) return errors;
      !this.$v.rol.maxLength &&
        errors.push(
          "Rol que desempeñas debe de ser menor a 255 caracteres de longitud"
        );
      !this.$v.rol.required && errors.push("Rol que desempeñas es requerido.");
      return errors;
    },
    dominioErrors() {
      const errors = [];
      if (!this.$v.dominio.$dirty) return errors;
      !this.$v.dominio.maxLength &&
        errors.push(
          "Dominio de GSuite for Education debe de ser menor a 255 caracteres de longitud"
        );
      !this.$v.dominio.required &&
        errors.push("Dominio de GSuite for Education es requerido.");
      return errors;
    },
  },

  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        console.log("enviar solicitud");
        this.loading = true;
        this.$api
          .post("/api/solicitud", {
            centro_educativo: this.centro_educativo,
            rol_desempeñado: this.rol,
            dominio_gsuite: this.dominio,
            administrador: this.administrador === "Si" ? true : false,
          })
          .then((response) => {
            console.log(response.data);
            this.solicitado = true;
            this.loading = false;
            /*if (response.data.success) {
              this.alert = true;
              this.hideAlert();
              this.dialog = false;
              this.selected = [];
              this.cantidad = 0;
              this.$nextTick(() => {
                this.copiedItem = Object.assign({});
                //this.editedIndex = -1
              });

            }*/
            //this.cursos = response.data
            //this.loading = false
          });
      }
    },
  },
  mounted() {
    this.solicitado = this.$store.getters.usuario.solicitado;
  },
  watch: {
    "$store.getters.usuario.solicitado": function() {
      //console.log(this.$store.getters.usuario.solicitado);
      this.solicitado = this.$store.getters.usuario.solicitado;
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('v-subheader',[_vm._v("Cursos")])],1),_c('v-col',{attrs:{"cols":12}},[_c('v-alert',{attrs:{"dismissible":"","type":"success"},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" Cursos copiando, se notificara via correo cuando termine. ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.cursos,"loading":_vm.loading,"search":_vm.search,"single-select":_vm.singleSelect,"item-key":"id","loading-text":"Cargando...","show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"white","flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","hide-details":"","label":"Buscar","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.copyItem()}}},'v-btn',attrs,false),[_vm._v("Copiar")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre"},model:{value:(_vm.copiedItem.name),callback:function ($$v) {_vm.$set(_vm.copiedItem, "name", $$v)},expression:"copiedItem.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Descripcion"},model:{value:(_vm.copiedItem.description),callback:function ($$v) {_vm.$set(_vm.copiedItem, "description", $$v)},expression:"copiedItem.description"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Cantidad de cursos a copiar","type":"number"},model:{value:(_vm.cantidad),callback:function ($$v) {_vm.cantidad=$$v},expression:"cantidad"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.copiarCursos}},[_vm._v("Crear")])],1)],1)],1)],1)]},proxy:true},{key:"item.alternateLink",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"disabled":!item.alternateLink,"color":"orange"},on:{"click":function($event){return _vm.abrirCurso(item.alternateLink)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-google-classroom ")])]}}],null,true)},[_c('span',[_vm._v("Ver Curso")])])]}},{key:"item.creationTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.creationTime))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
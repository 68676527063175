<template>

<v-row>

  
    <v-col :cols="12">
        <v-subheader>Reeset masivo</v-subheader>
        <br>
            <v-alert
              dense
              type="info"
              prominent
            >
           A continuación, seleccionar una de las siguientes opciones para usarla como filtro de acceso.
           <br>
           <span style="text-align:justify">
           Ej. Si hoy fuera 20 de Marzo y se eligiese la opción 15, se seleccionarían los correos cuyo <b> ÚLTIMO ACCESO</b>  a la cuenta, haya sido <b> ANTES del 5 de marzo</b>.
          </span>
        </v-alert>
    </v-col>


    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >

      <v-card>
        <v-card-title class="headline">
          ¡Es necesario elegir <br> una opción!
        </v-card-title>
        
        <v-card-actions>
          <v-spacer></v-spacer>
         <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col :cols="12">
        <v-alert v-model="alert" type="success" dismissible>
            ¡Acción en proceso!
        </v-alert>

         <v-card elevation="5" style="padding:50px">
        <v-toolbar flat color="white">
            
            <v-subheader>Elegir rango de días</v-subheader>
            <v-radio-group row
            v-model="periodo"
            >
            <v-radio
                label="15"
                color="yellow"
                value="15"
              ></v-radio>
              <v-radio
                label="30"
                color="orange"
                value="30"
              ></v-radio>
              <v-radio
                label="45"
                color="red"
                value="45"
              ></v-radio>
            <v-radio
                label="90"
                color="red darken-3"
                value="90"
              ></v-radio>
            </v-radio-group>
            <v-spacer></v-spacer>    
        <v-btn
            @click="resetPass"
            color="secondary"
            elevation="5"
            >Reestablecer contraseña
        </v-btn>
    </v-toolbar>
    </v-card>
    <br>    
    </v-col>
</v-row>



</template>

<script>
export default {
    name: "ResetPassMasivo",
    data(){
        return{
            dialog: false,
             loading: false,
             alert: false,
             periodo: null
        }//return
    }//data
    ,computed:{

}//computed
    ,methods:{
             resetPass(){

                if(!this.periodo){
                    this.dialog = true;
                    return
                }
                console.log("entra al flujo")
                
                this.loading = true;
                    this.$api.post('/api/cuentas-reset-masivo', {
                        periodo : parseInt(this.periodo)
                    })
                     .then((response) => {
                        console.log(response.data)
                     }) 
                     .catch(function (error) {
                        console.log(error)
                    })
                    
                this.loading = false;
          
            return;
        }//resetPass  


    },//methods



};
</script>



<style scoped>

</style>


<template>
  <v-row>
    <v-col :cols="12">
      <v-subheader>Panel de administradores</v-subheader>
    </v-col>
    <v-col :cols="12">
      <v-alert v-model="alert" dismissible type="success">
        Cursos copiando, se notificara via correo cuando termine.
      </v-alert>
      <v-btn @click="importarCuentas" class="mb-5 primary white--text">Importar cuentas</v-btn>




      <v-dialog
          v-model="dialogDetallesCuenta"
          persistent
          max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Detalles de la cuenta</span>
          </v-card-title>
          <v-card-text>
            <template>

              <v-alert
                  dense
                  type="info"
              >
                <strong>Estatus de la cuenta:</strong> {{ cuenta_usuario.estatus_cuenta }}
              </v-alert>

              <v-text-field v-model="cuenta_usuario.nombres" placeholder="Nombre"></v-text-field>

              <v-text-field v-model="cuenta_usuario.apellidos" placeholder="Apellidos"></v-text-field>

              <v-text-field v-model="cuenta_usuario.correo" placeholder="Correo"></v-text-field>

              <v-text-field v-model="cuenta_usuario.clave_modular" placeholder="Clave modular"></v-text-field>

              <br>

              <v-btn @click="guardarCambiosCuenta" class="primary">Guardar</v-btn>

            </template>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialogDetallesCuenta = false"
            >
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
          v-model="dialogCuentas"
          persistent
          max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Importar cuentas</span>
          </v-card-title>
          <v-card-text>
            <v-alert
                dense
                type="info"
            >
              Usted debe de subir su documento <strong>CSV</strong> a su Google Drive, hacerlo público y pegar el link del vínculo en este formulario. Descargue la plantilla
              <a target="_blank" class="white--text" href="https://drive.google.com/file/d/1LQry-mMtueIx_ejOU8WmK1r81lxxMMIn/view?usp=sharing">aquí</a>
            </v-alert>
            <template>
              <v-img src="https://i.imgur.com/R5O9AU4.png"></v-img>
<!--              <v-file-input
                  v-model="documentoImportacion"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  label="Por favor seleccione su documento"
              ></v-file-input>-->
              <v-text-field v-model="ruta_documento_drive" placeholder="Ruta del documento de DRIVE"></v-text-field>
            </template>

<!--            <v-img src="https://i.imgur.com/28tEQrw.png"></v-img>-->

            <br>
            <v-progress-circular
                :size="50"
                v-if="loadingDocument"
                color="primary"
                indeterminate
            ></v-progress-circular>
            <v-btn v-if="!loadingDocument" @click="subirDocumento" class="primary white--text">Confirmar carga</v-btn>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialogCuentas = false"
            >
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-data-table
          v-model="selected"
          :headers="headers"
          :items="itemsCuentas"
          :loading="loading"
          :search="search"
          :single-select="singleSelect"
          class="elevation-1"
          item-key="id"
          loading-text="Cargando..."
      >
        <template v-slot:top>
          <v-toolbar color="white" flat>
            <!--<v-toolbar-title>Cursos</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>-->
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                hide-details
                label="Buscar"
                single-line
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-select 
                @change="getAdministradores"
                label="Filtrar por estatus de cuentas"
                class="ma-5"
                v-model="estatus_filtros"
                item-text="nombre_estatus"
                item-value="id"
                :items="itemsFiltros"
                return-object></v-select>
            <img title="Exportar filtro" style="cursor:pointer;" @click="exportarFiltro()" src="https://i.imgur.com/Sz1Le8r.png" alt="">
            <v-btn title="Recargar" icon color="grey" @click="getAdministradores()">
              <v-icon>mdi-cached</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{item}">
          <v-btn @click="verRegistro(item)" fab small class="green white--text"><v-icon>mdi-eye</v-icon></v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Cursos",
  data() {
    return {
      estatus_filtros : '',
      itemsFiltros : [],
      cuenta_usuario : {
        cuenta_administrador_id : 0,
        nombres : '',
        apellidos : '',
        correo : '',
        clave_modular : '',
        estatus_cuenta : ''
      },
      dialogDetallesCuenta : false,
      ruta_documento_drive : '',
      documentoImportacion : [],
      loadingDocument : false,
      dialogCuentas : false,
      search: "",
      alert: false,
      loading: false,
      dialog: false,
      singleSelect: true,
      copyDisabled: "disabled",
      cantidad: 0,
      selected: [],
      headers: [
        { text: "#", value: "id" },
        { text: "Nombres", value: "nombres" },
        { text: "Apellidos", value: "apellidos" },
        { text: "Correo", value: "correo" },
        { text: "Clave modular", value: "clave_modular" },
        { text: "Estatus cuenta", value: "estatus_cuenta.nombre_estatus" },
        { text: "Acciones", value: "actions" },
      ],
      itemsCuentas: [],
      copiedItem: {},
    };
  },
  mounted() {
    this.getAdministradores();
    this.getEstatusCuentas();
  },
  methods: {
    exportarFiltro() {
      let estatus = 0;
      var r = confirm("¿Confirma usted generar este reporte ahora?");
      if ( r != true ) {
        return false;
      }
      if ( this.estatus_filtros && this.estatus_filtros.id != 0 ) {
        estatus = this.estatus_filtros.id;
      }
      this.$api.post("/api/panel-administrador/exportar-cuentas", {
        estatus_cuenta : estatus
      })
      .then(response => {
        alert("La exportación finalizó correctamente, le enviaremos un mail con el documento que contiene su exportación de datos")
        console.log(response)
      })
      .catch(error => {
        console.log(error)
      })
    },
    getEstatusCuentas() {
      this.$api.get("/api/estatus-cuentas-admin")
      .then(response => {
        this.itemsFiltros = response.data;
        let todos = {
          id : 0,
          nombre_estatus : "Todos"
        };
        this.itemsFiltros.unshift(todos);
        this.estatus_filtros = todos;
      })
      .catch(error => {
        console.log(error)
      })
    },
    guardarCambiosCuenta() {
      this.$api.post('/api/panel-administrador/' + this.cuenta_administrador_id, {
        nombres : this.cuenta_usuario.nombres,
        apellidos : this.cuenta_usuario.apellidos,
        correo : this.cuenta_usuario.correo,
        clave_modular : this.cuenta_usuario.clave_modular,
        _method : 'PATCH'
      })
      .then(response => {
        console.log(response)
        if ( response.data ) {
          alert("Cuenta editada correctamente")
          this.getAdministradores();
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    verRegistro(item) {
      console.log(item)
      this.cuenta_administrador_id = item.id;
      this.cuenta_usuario.nombres = item.nombres;
      this.cuenta_usuario.apellidos = item.apellidos;
      this.cuenta_usuario.correo = item.correo;
      this.cuenta_usuario.clave_modular = item.clave_modular;
      this.cuenta_usuario.estatus_cuenta = item.estatus_cuenta.nombre_estatus;
      this.dialogDetallesCuenta = true;
    },
    subirDocumento() {
      var r = confirm("¿Subir documento?");
      if ( r == true ) {
        this.loadingDocument = true;
        this.$api.post('/api/panel-administrador/importar-cuentas', {
          ruta_documento_drive : this.ruta_documento_drive
        })
        .then(response => {
          console.log(response)
          alert("Importación iniciada, recibirá un correo electrónico de confirmación cuando la importación finalice")
          this.dialogCuentas = false;
          this.loadingDocument = false;
          /*setTimeout(() => {
            this.getAdministradores();
          }, 5000);*/
        })
        .catch(error => {
          // code here when an upload is not valid
          console.log(error)
          alert("Importación iniciada, recargue la página para ver sus nuevos registros")
          this.dialogCuentas = false;
        })
      }

/*      let formData = new FormData();
      formData.append('import_file', this.documentoImportacion);

      this.$api.post('/api/importar-cuentas', formData, {
        headers: { 'content-type': 'multipart/form-data' }
      })
      .then(response => {
        if(response.status === 200) {
          // codes here after the file is upload successfully
        }
      })
      .catch(error => {
        // code here when an upload is not valid
        console.log(error)
      });*/
    },
    importarCuentas() {
      this.dialogCuentas = true;
    },
    getAdministradores() {
      this.loading = true;
      this.itemsCuentas = [];
      let endpoint = "/api/panel-administrador";
      if ( this.estatus_filtros && this.estatus_filtros.id != 0 ) {
        endpoint = "/api/panel-administrador?estatus=" + this.estatus_filtros.id;
      }
      this.$api.get(endpoint)
      .then(response => {
        this.itemsCuentas = response.data;
        this.loading = false;
      })
      .catch(error => {
        console.log(error)
      })
    }
  },
};
</script>

<style></style>

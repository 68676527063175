<template>
  <v-row justify="center" align="center" class="fill-height">
    <v-col align="center">
      <v-img
        src="android-chrome-192x192.png"
        aspect-ratio="1"
        height="100px"
        width="100px"
      />
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios'
//axios.defaults.withCredentials = true;
//axios.defaults.baseURL = 'http://localhost:8000/'
/*import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
*/
export default {
  name: "Home",
  data() {
    return {
      /*
      url: process.env.VUE_APP_URL,
      title: process.env.VUE_APP_TITLE
      */
    };
  },
  mounted() {
    /*axios.get('/api/user').then(response => {
            this.email = response.data.email
            this.avatar = response.data.avatar
        });*/
    /*
    this.$api.get('/api/user').then(response => {
            this.email = response.data.email
            this.avatar = response.data.avatar
        })*/
  },

  methods: {
    /*login(){
        axios.get('/sanctum/csrf-cookie').then(() => {
            axios.post('/login', {
              email: '',
              password: '',
            }).then(() => {
              //console.log(response)
              this.$router.push({ name: 'Dashboard'})
            })
        });
    },
    loginGoogle(){
      axios.get('/sanctum/csrf-cookie').then(() => {
            window.location = "http://localhost:8000/redirect"
        });
    }*/
  },
  computed: {
    redirect() {
      return this.$store.getters.usuario.redirect;
      // Or return basket.getters.fruitsCount
      // (depends on your design decisions).
    },
  },
  watch: {
    redirect(newValue) {
      // Our fancy notification (2).
      //console.log(`We have ${newValue} fruits now, yay!`);
      this.$router.push({ path: newValue });
    },
  },
};
</script>

<template>
<v-container class="grey lighten-5">
  <v-row>
                <v-col :cols="12">                     
                     <v-card elevation="4">
                    
                    <v-card-title>
                    
                        <v-icon large class="mr-2" color="purple">
                            mdi mdi-file-document
                        </v-icon>
                        <h3>Analiza accesos</h3>
                         <v-btn  v-if="cantidadAccesosActuales<2"
                                  color="primary"
                                  dark
                                  class="my-2 ml-auto "
                                  @click="abrirDialogAnaliza()"
                              >Nuevo acceso
                              </v-btn>

                         <v-btn  v-if="cantidadAccesosActuales>=2"
                                  color="red"
                                  dark
                                  class="my-2 ml-auto "
                               
                              >No es posible agregar más
                              </v-btn>

                    </v-card-title>
                            <div style="margin-left:50px" >
                            <router-link style="text-decoration: none; color: inherit;" to="/registroEscuelas">
                                  Volver
                            </router-link>
                            </div>
                    <v-card-text>

                      <v-alert
                          dense
                          type="info"
                          prominent
                          >
                      La herramienta ANALIZA sólo admite 2 usuarios como máximo, puede quitar y agregar accesos según sea necesario.
                  
                          <br>
                      </v-alert>

                      <v-snackbar
                              v-model="snackbar"
                              :multi-line="multiLine"
                              :color="colorSnackbar"
                              :timeout="timeout"
                          >
                          {{textoSnackbar}}
                          <template v-slot:action="{ attrs }">
                              <v-btn
                              color="white"
                              text
                              v-bind="attrs"
                              @click="snackbar = false"
                              >
                              Cerrar
                              </v-btn>
                          </template>
                      </v-snackbar>

                        <v-data-table
                        :headers="headers"
                        :items="resultSet"
                        :search="search"
                        >
                          <template v-slot:item.id="{ item }">
                          <v-btn style="color: #fff; margin-left:10px" 
                                    elevation="2"
                                    color="red"
                                    @click="deleteItem(item.acceso_analiza_id)"
                                    >X
                          </v-btn>
                          </template>
                        </v-data-table>
                    </v-card-text>
                    </v-card>
                </v-col>  

                 <v-dialog
                                                            v-model="dialog"
                                                            persistent
                                                            max-width="600"
                                                            >
                                                            <v-card>
                                                                <v-card-title class="headline">
                                                              Acceso Analiza
                                                                </v-card-title>
                                                                <v-card-text>
                                                            
                                <v-card elevation="3" style="padding:15px">
                                        <v-card-title class="headline">
                                          <b>  Nuevo acceso a Analiza</b>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-text-field
                                                v-model="ApellidoPaternoAnaliza"
                                                label="Apellido Paterno"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="ApellidoMaternoAnaliza"
                                                label="Apellido Materno"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="NombresAnaliza"
                                                label="Nombres"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="RUTAnaliza"
                                                label="CURP"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="CorreoElectronicoDominioAnaliza"
                                                label="Correo Electrónico de dominio"
                                            ></v-text-field>                         
                                            <v-text-field
                                                v-model="CargoPrincipalAnaliza"
                                                label="Cargo principal"
                                            ></v-text-field>   
                         
                                                    <div></div>
                                            <v-btn
                                                elevation="4"
                                                large
                                                block
                                                @click="accesoAnaliza()"
                                                >Registrar</v-btn>
                                        </v-card-text>
                                </v-card>
                                        </v-card-text>
                                        <v-card-actions> 
                                            <v-spacer></v-spacer>
                                                <v-btn
                                                    color="green darken-1"
                                                    text
                                                    @click="dialog = false"
                                                >
                                                    Cancelar
                                                </v-btn>
                                                
                                        </v-card-actions>
                                    </v-card>
                 </v-dialog>

           </v-row>
</v-container>
</template>

<script>
import Swal from 'sweetalert2';
export default {


data() {
    return {

        timeout:2000,
        snackbar:false,
        textoSnackbar:"",
        colorSnackbar:"",
        multiLine: true,

        ApellidoPaternoAnaliza:'',
        ApellidoMaternoAnaliza:'',
        NombresAnaliza:'',
        RUTAnaliza:'',
        CorreoElectronicoDominioAnaliza:'',
        CargoPrincipalAnaliza:'',

        dialog:false,
        isAddButtonDisabled: false,
        cantidadAccesosActuales:0,
        isValid:true,
        search: '',
        headers: [
          { text: 'Correo:', align: 'start', value: 'email' },
          { text: 'Puesto', value: 'jobPosition' },
          { text: 'Nombre', value: 'name' },
          { text: 'Apellido paterno', value: 'lastName' },
          { text: 'Nombre materno', value: 'lastName2' },
           { text: 'Accesos',   value: 'id',  align:'center'}
        ],
        resultSet:[]
       
    }
},

    mounted(){
         this.getData();
    },

methods: {
  normalSnackbar(color, text){
                    this.colorSnackbar  = color;
                    this.textoSnackbar  = text;
                    this.snackbar       = true;
    },
    cleanVars(){
            this.ApellidoPaternoAnaliza           =null;
            this.ApellidoMaternoAnaliza           =null;
            this.NombresAnaliza                   =null;
            this.RUTAnaliza                       =null;
            this.CorreoElectronicoDominioAnaliza  =null;
            this.CargoPrincipalAnaliza            =null;
        },
    ValidateFields(op ,id){
        if(op==1){   
            if( id == "" || id == null || id == 0){ this.isValid = false;}
            if( this.ApellidoPaternoAnaliza == "" || this.ApellidoPaternoAnaliza == null){ this.isValid = false;}
            if( this.ApellidoMaternoAnaliza== "" || this.ApellidoMaternoAnaliza == null){ this.isValid = false;}
            if( this.NombresAnaliza== "" || this.NombresAnaliza == null){ this.isValid = false;}
            if( this.RUTAnaliza== "" || this.RUTAnaliza == null){ this.isValid = false;} 
            if( this.CorreoElectronicoDominioAnaliza== "" || this.CorreoElectronicoDominioAnaliza == null){ this.isValid = false;}   
            if( this.CargoPrincipalAnaliza== "" || this.CargoPrincipalAnaliza == null){ this.isValid = false;}              
        }

        if( this.isValid == false) { 
                this.textoSnackbar  = "Es necesario todos los campos en el formulario";
                this.colorSnackbar  = "deep-orange darken-3";
                this.snackbar       = true;
                return false;
        }else{
            this.isValid == true;
            return true;
        }
    },
    async getData(){
          const id  = (this.$route.params.id) ? (this.$route.params.id) : 0;
          const url = `/api/get-analizaAccesos/${id}`;
          const response = await this.$api.get(url);
          let {
                data,
                accesos
              } = response.data;

          if(data==1){
                this.resultSet = accesos;
                this.cantidadAccesosActuales = this.resultSet.length; 
            }else if(data==3 ||  data==2){
                console.log("No hay registros");
            }
   
    },
    deleteItem(item){
      let index = this.resultSet.indexOf(item);
      Swal.fire({
        title: '¿Está seguro que desea eliminar este acceso? ',
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then((result) => {
                
         console.log(item);
        if (result.isConfirmed) {
        const url = `/api/delete-accesoAnaliza/${item}`;
          this.$api.get(url)
          .then( (response) => {
            console.log(response.data);
            let { message
                , color
                , data
                } = response.data;
            this.normalSnackbar(color, message); 

              if(data == 1){
                this.resultSet.splice(index, 1);
                this.getData();
              }

         })
          .catch(function (e) {
                this.textoSnackbar  = "Ocurrió un error general, favor de comunicarse con su administrador: " + e.name + ': ' + e.message;
                this.colorSnackbar  = "deep-orange darken-3";
                this.snackbar       = true;
          });
          
        } else if (result.isDenied) {
           console.log("cancelado!")
        }
        
      });


        },//deleteItem
    abrirDialogAnaliza(){
        this.dialog = true;
    },
    async accesoAnaliza(){      
          const id  = (this.$route.params.id) ? (this.$route.params.id) : 0;
          const url = `/api/save-accesoAnaliza`;
          if(this.ValidateFields(1, id)==false){return false;}

        try {
         const response = await this.$api.post(url,  {
                    id:           id,
                    name:         this.NombresAnaliza,
                    lastName:     this.ApellidoPaternoAnaliza,
                    lastName2:    this.ApellidoMaternoAnaliza,
                    code:         this.RUTAnaliza,
                    email:        this.CorreoElectronicoDominioAnaliza,
                    jobPosition:  this.CargoPrincipalAnaliza
                    });
            
            console.log(response.data);

            let { message
                , color
                } = response.data;

                 this.normalSnackbar(color, message); 
                 this.dialog=false;
                 this.getData();
                 this.cleanVars();
                 this.dialog2 = false;
        } catch (e) {
                this.textoSnackbar  = "Ocurrió un error general, favor de comunicarse con su administrador: " + e.name + ': ' + e.message;
                this.colorSnackbar  = "deep-orange darken-3";
                this.snackbar       = true;
        }
        
    },

},
}
</script>

<style>

</style>
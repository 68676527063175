/* eslint-disable vue/no-unused-vars */
<template>
   <v-container>
       <v-subheader>Tickets</v-subheader>
        <v-row>
            <v-card elevation="5" style="padding:50px">
                <v-card-title class="headline">
               Asigna
                </v-card-title>
                
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="12" sm="12">
                                <br>
                                <v-alert
                                    dense
                                    type="info"
                                    prominent
                                    >
                                   A continuación, se muestra el listado de los colegios y sus respectivos accesos a las herramientas.
                                   Es posible dar acceso a una persona a la herramienta de AULA y 2 personasa la herramienta ANALIZA.
                                    <br>
                                </v-alert>

                            <v-snackbar
                                v-model="snackbar"
                                :multi-line="multiLine"
                                :color="colorSnackbar"
                                :timeout="timeout"
                            >
                            {{textoSnackbar}}
                            <template v-slot:action="{ attrs }">
                                <v-btn
                                color="white"
                                text
                                v-bind="attrs"
                                @click="snackbar = false"
                                >
                                Cerrar
                                </v-btn>
                            </template>
                        </v-snackbar>

                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="12" sm="12">
                                        <div style="text-align:right; ">
              
                                        <router-link style="text-decoration: none;padding-right:15px" :to="`/importar-escuelas`">
                                                    <v-btn style="color: #fff"
                                                    elevation="4"
                                                    color="purple"
                                                    ><span style="color: #fff"> Importar escuelas</span>
                                                    </v-btn>
                                        </router-link>
                                        
                                            
                                            <v-btn
                                                color="purple" elevation="4"
                                                @click="abrirDialogSolicitud()"
                                            >
                                                <span style="color: #fff"> Nueva escuela</span>
                                            </v-btn>


                                            <br><br>
                                        </div>
                                                        <v-dialog
                                                            v-model="dialog"
                                                            persistent
                                                            max-width="600"
                                                            >
                                                            <v-card>
                                                                <v-card-title class="headline">
                                                              Alta nueva escuela
                                                                </v-card-title>
                                                                <v-card-text>
                                     <v-card elevation="3" style="padding:15px">
                                        <v-card-title class="headline">
                                          <b>  Nuevo registro de escuela</b>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-text-field
                                                v-model="nombreEscuela"
                                                label="Nombre escuela"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="RBDescuela"
                                                label="Clave escuela"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="direccionEscuela"
                                                label="Direccion escuela"
                                            ></v-text-field>
                                                <v-autocomplete
                                                    clearable
                                                    placeholder="Elegir"
                                                    label="Elegir"

                                                    v-model="cargo_id"
                                                    item-text="name"
                                                    item-value="id"
                                                    :items="item_escuelas"
                                                    ></v-autocomplete>

                                                  

                                                    <div></div>
                                            <v-btn
                                                elevation="4"
                                                large
                                                block
                                                @click="guardarEscuela()"
                                                >Registrar</v-btn>
                                        </v-card-text>
                                </v-card> 
                                                                </v-card-text>
                                                                <v-card-actions> 
                                                                    <v-spacer></v-spacer>
                                                                        <v-btn
                                                                            color="green darken-1"
                                                                            text
                                                                            @click="dialog = false"
                                                                        >
                                                                            Cancelar
                                                                        </v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                            </v-dialog>



                                                         <v-dialog
                                                            v-model="dialog5"
                                                            persistent
                                                            max-width="600"
                                                            >
                                                            <v-card>
                                                                <v-card-title class="headline">
                                                                    Usuario con acceso actual a AULA
                                                                </v-card-title>
                                                                    <v-card-text>
                                                                            <v-card elevation="3" style="padding:15px">
                                                                                    <v-card-title class="headline">

                                                                                    </v-card-title>
                                                                                    <v-card-text>
                                                                                        <h2>Nombre: {{NombresAula}} </h2>
                                                                                        <h2>CURP: {{RUTAula}}</h2>
                                                                                        <h2>Correo electrónico {{CorreoElectronicoDominioAula}}</h2>
                                                                                        <h2>Cargo principal {{CargoPrincipalAula}}</h2>                         
                                                                                    </v-card-text>
                                                                            </v-card>       
                                                                    </v-card-text>
                                                                <v-card-actions> 
                                                                    <v-spacer></v-spacer>
                                                                        <v-btn
                                                                            color="green darken-1"
                                                                            text
                                                                            @click="dialog5 = false"
                                                                        >
                                                                            Cancelar
                                                                        </v-btn>
                                                                        
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-dialog>

                                                         <v-dialog
                                                            v-model="dialog3"
                                                            persistent
                                                            max-width="600"
                                                            >
                                                            <v-card>
                                                                <v-card-title class="headline">
                                                             Acceso Aula
                                                                </v-card-title>
                                                                <v-card-text>
                                             <v-card elevation="3" style="padding:15px">
                                        <v-card-title class="headline">
                                <v-alert
                                    dense
                                    type="warning"
                                    prominent
                                    >  
                                 Sólo es posible un acceso a aula, si ya se tiene a alguien registrado, ESTE SERÁ REEMPLAZADO por el registo que se vaya a dar de alta
                                    <br>
                                </v-alert>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-text-field
                                                v-model="ApellidoPaterno"
                                                label="Apellido Paterno"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="ApellidoMaterno"
                                                label="Apellido Materno"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="Nombres"
                                                label="Nombres"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="RUT"
                                                label="CURP"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="CorreoElectronicoDominio"
                                                label="Correo Electrónico de dominio"
                                            ></v-text-field>                         
                                            <v-text-field
                                                v-model="CargoPrincipal"
                                                label="Cargo principal"
                                            ></v-text-field>    
                                                    <div></div>
                                            <v-btn
                                                elevation="4"
                                                large
                                                block
                                                @click="accesoAula()"
                                                >Registrar</v-btn>
                                        </v-card-text>
                                </v-card>       
                                                                </v-card-text>
                                                                <v-card-actions> 
                                                                    <v-spacer></v-spacer>
                                                                        <v-btn
                                                                            color="green darken-1"
                                                                            text
                                                                            @click="dialog3 = false"
                                                                        >
                                                                            Cancelar
                                                                        </v-btn>
                                                                        
                                                                </v-card-actions>
                                                            </v-card>
                                                            </v-dialog>


                                    <v-dialog
                                                            v-model="dialog2"
                                                            persistent
                                                            max-width="600"
                                                            >
                                                            <v-card>
                                                                <v-card-title class="headline">
                                                            Datos de la escuela
                                                                </v-card-title>
                                                                <v-card-text>
                                             <v-card elevation="3" style="padding:15px">
                                        <v-card-title class="headline">
                                <v-alert
                                    dense
                                    type="warning"
                                    prominent
                                    >  
                                        Al desactivar el registro de una escuela, esta acción por si misma inhabilita los accesos de los usuarios ligados a esta a las herramientas de aula y analiza
                                    <br>
                                </v-alert>
                                        </v-card-title>
                                        <v-card-text>
                                         

                                            <v-text-field
                                                v-model="nombreEscuela"
                                                label="Nombre escuela"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="RBDescuela"
                                                label="RBD escuela"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="direccionEscuela"
                                                label="Direccion escuela"
                                            ></v-text-field>
                                                <v-autocomplete
                                                    clearable
                                                    placeholder="Elegir"
                                                    label="Elegir"

                                                    v-model="cargo_id"
                                                    item-text="name"
                                                    item-value="id"
                                                    :items="item_escuelas"
                                                    ></v-autocomplete>

                                                Estatus: <span v-if="escuelaEstatus == 1"> Activa </span> <span v-if="escuelaEstatus == 0"> Desactivada </span>
                                                <v-switch
                                                    v-model="escuelaEstatus"
                                                ></v-switch>


                                                <div></div>
                                            <v-btn
                                                elevation="4"
                                                large
                                                block
                                                @click="actualizarEscuela()"
                                                >Actualizar</v-btn>
                                        </v-card-text>
                                </v-card>       
                                                                </v-card-text>
                                                                <v-card-actions> 
                                                                    <v-spacer></v-spacer>
                                                                        <v-btn
                                                                            color="green darken-1"
                                                                            text
                                                                            @click="cerrarDialogActualizarEscuela()"
                                                                        >
                                                                            Cancelar
                                                                        </v-btn>
                                                                        
                                                                </v-card-actions>
                                                            </v-card>
                                                            </v-dialog>




                                    <v-card>
                                        <v-card-title>
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            label="Buscar"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                        </v-card-title>
                                        
                                        <v-data-table
                                        :item-class= "row_classes" 
                                        :headers="headers"
                                        :items="resultSet"
                                        :search="search">
                     
                                        <template v-slot:item.escuelaId="{ item }">
                                                <v-btn
                                                    class="ma-2"
                                                    outlined
                                                    elevation="2"
                                                    fab
                                                    small
                                                    color="primary"
                                                    @click="abrirDialogEscuela(item.escuelaId)"
                                                >
                                                <v-icon>mdi-pencil</v-icon>
                                                </v-btn>
                                        </template>

                                        <template v-slot:item.acceso_aulasEmail="{ item }">
                                                <span>{{item.acceso_aulasEmail}}</span>
                                               <v-btn v-if="item.aulaAcceso!= null"
                                                 style="color: #fff; padding-left:5px" 
                                                  x-small
                                                    elevation="2"
                                                    color="primary"
                                                    @click="abrirDialogAulaDetalleAcceso(item.aulaAcceso)"
                                                    >ver
                                                </v-btn>
                                        </template>

                                        <template v-slot:item.id="{ item }">
                                            <!--
                                            <router-link style="text-decoration: none;" :to="`/analiza-acceso/${item.id}`">
                                                        <v-btn style="color: #fff"
                                                        elevation="4"
                                                        color="purple"
                                                        >Analiza
                                                        </v-btn>
                                            </router-link>
                                            -->
                                            <v-btn style="color: #fff; margin-left:10px" 
                                                        elevation="4"
                                                        color="primary"
                                                        @click="abrirDialogAula(item.id)"
                                                        >Aula
                                            </v-btn>
                                        </template>

                                        </v-data-table>
                                    </v-card>

                            </v-col>
                        </v-row>
                        
                    </v-container>
                </v-card-text>
            </v-card>

        </v-row>
  </v-container>
</template>

<script>

export default {
data() {
    return {

        timeout:2000,
        snackbar:false,
        textoSnackbar:"",
        colorSnackbar:"",
        multiLine: true,

         NombresAula: null,
         RUTAula:null,
         CorreoElectronicoDominioAula:null,
         CargoPrincipalAula:null,
   
        texto1:'',
        texto2:'',
        texto3:'',
        texto4:'',
        dialog3:false,
        dialog2:false,
        dialog5:false,
      
        dialog:false,
        selected_id:'',
        resultSet:[],
        item_escuelas: [],
        nombreEscuela:'',
        RBDescuela:'',
        direccionEscuela:'',
        cargo_id:'',
        escuelaEstatus:'',

        ApellidoPaterno:'',
        ApellidoMaterno:'',
        Nombres:'',
        RUT:'',
        CorreoElectronicoDominio:'',
        CargoPrincipal:'',
        isValid:true,
          search: '',
          headers: [
    
           { text: ' ', align: 'start', value: 'escuelaId' },
           { text: 'Colegio', align: 'start', value: 'name' },
           { text: 'Acceso a Aula', value: 'acceso_aulasEmail' },
           { text: 'Clave', value: 'code' },
           { text: 'Accesos',   value: 'id',  align:'center'}
        ],
    }
},
methods: {
    normalSnackbar(color, text){
                    this.colorSnackbar  = color;
                    this.textoSnackbar  = text;
                    this.snackbar       = true;
    },
    cleanVars(){
        this.nombreEscuela              =null;
        this.RBDescuela                 =null;
        this.direccionEscuela           =null;
        this.cargo_id                   =null;
        this.escuelaEstatus             =null;

        this.Nombres                    =null;
        this.ApellidoPaterno            =null;
        this.ApellidoMaterno            =null;
        this.RUT                        =null;
        this.CorreoElectronicoDominio   =null;
        this.CargoPrincipal             =null;
    },
    row_classes(item) {
        if (item.estatus == 0) {
          return "red lighten-5";
        } 
    },
    abrirDialogAulaDetalleAcceso(item){
        this.dialog5 = true;
        this.$api.get(`/api/get-accesoAula/${item}`)
            .then((response) => {
            let { NombresAula
                , RUTAula
                , CorreoElectronicoDominioAula 
				, CargoPrincipalAula
                } = response.data;

         this.NombresAula                   = NombresAula;     
         this.RUTAula                       = RUTAula;
         this.CorreoElectronicoDominioAula  = CorreoElectronicoDominioAula;
         this.CargoPrincipalAula            = CargoPrincipalAula;
      });

    },
    ValidateFields(op){
        if(op==1){   
            if( this.nombreEscuela == "" || this.nombreEscuela == null){ this.isValid = false;}
            if( this.RBDescuela== "" || this.RBDescuela == null){ this.isValid = false;}
            if( this.direccionEscuela== "" || this.direccionEscuela == null){ this.isValid = false;}
            if( this.cargo_id== "" || this.cargo_id == null){ this.isValid = false;}            
        }else if(op==2){
            if( this.selected_id== "" || this.selected_id == null){ this.isValid = false;}
            if( this.nombreEscuela == "" || this.nombreEscuela == null){ this.isValid = false;}
            if( this.RBDescuela== "" || this.RBDescuela == null){ this.isValid = false;}
            if( this.direccionEscuela== "" || this.direccionEscuela == null){ this.isValid = false;}
            if( this.cargo_id== "" || this.cargo_id == null){ this.isValid = false;}            
        }else if(op ==3){
            if( this.selected_id== "" || this.selected_id == null){ this.isValid = false;}
            if( this.Nombres == "" || this.Nombres == null){ this.isValid = false;}
            if( this.ApellidoPaterno == "" || this.ApellidoPaterno == null){ this.isValid = false;}
            if( this.ApellidoMaterno == "" || this.ApellidoMaterno == null){ this.isValid = false;}
            if( this.RUT == "" || this.RUT == null){ this.isValid = false;}
            if( this.CorreoElectronicoDominio == "" || this.CorreoElectronicoDominio == null){ this.isValid = false;}
            if( this.CargoPrincipal == "" || this.CargoPrincipal == null){ this.isValid = false;}
        }

        if( this.isValid == false) { 
                this.textoSnackbar  = "Es necesario todos los campos en el formulario";
                this.colorSnackbar  = "deep-orange darken-3";
                this.snackbar       = true;
                return false;
        }else{
            this.isValid == true;
            return true;
        }

    },
    async guardarEscuela(){
          const url = `/api/save-escuelas`;
          if(this.ValidateFields(1)==false){return false;}    
         const response = await this.$api.post( url ,{
                    name:           this.nombreEscuela,
                    code:           this.RBDescuela,
                    email:          this.direccionEscuela,
                    institution:    this.cargo_id
                    });
            let { message
                , color
                } = response.data;
                    this.normalSnackbar(color, message);                
                    this.getData();
                    this.cleanVars();
    },//guardarEscuela
   async accesoAula(){
            const url = `/api/save-accesoAula`;
            if(this.ValidateFields(3)==false){return false;}
            
           const response = await this.$api.post(url,  {
                    id:             this.selected_id,
                    name:           this.Nombres,
                    lastName:       this.ApellidoPaterno,
                    lastName2:      this.ApellidoMaterno,
                    code:           this.RUT,
                    email:          this.CorreoElectronicoDominio,
                    jobPosition:    this.CargoPrincipal
                    });
            let { message
                , color
                } = response.data;
            this.normalSnackbar(color, message);           
            this.getData();
            this.cleanVars();
            this.dialog3        = false;
    },
    abrirDialogSolicitud(){
                this.dialog = true;
    },  
    abrirDialogEscuela(item){
    
        this.selected_id = item;
        this.$api.get(`/api/show-escuela/${item}`)
            .then((response) => {
            let { name
                , code
                , domicilio 
				, institucion
                , estatus
                } = response.data;
        console.log(response.data);
         this.nombreEscuela         = name;     
         this.RBDescuela            = code;
         this.direccionEscuela      = domicilio;
         this.cargo_id              = Number( institucion );
         this.escuelaEstatus        = estatus;
      });    
        this.dialog2 = true;
    },
   async actualizarEscuela(){
        const url = `/api/update-escuelas`;
        if(this.ValidateFields(2)==false){return false;}
        const response = await this.$api.post(url,  {
                    id  :           this.selected_id,
                    name:           this.nombreEscuela,
                    code:           this.RBDescuela,
                    domicilio:      this.direccionEscuela,
                    institution:    this.cargo_id,
                    estatus :       this.escuelaEstatus
                    });
		let { message
            , color
            } = response.data;
            this.normalSnackbar(color, message);  
            this.getData();
    },
    cerrarDialogActualizarEscuela(){
                    this.dialog2 = false;
                    this.cleanVars();
    }
    ,abrirDialogAula(item){
        this.selected_id=item;
         this.dialog3 = true;
    },
    async getData(){
                this.loading = true;
                const response = await this.$api.get("/api/escuelas");
                this.resultSet = response.data;
                this.loading = false;
    },
    getParametrizacion(){
        /*
        this.loading = true;
        this.$api.get("/api/get-parametrizacion")
        .then((response) => {
        // this.resultSet = response.data;
            this.texto1=response.data[0]['texto1'];
            this.texto2=response.data[0]['texto2'];
            this.texto3=response.data[0]['texto3'];
            this.texto4=response.data[0]['texto4'];
        })
        this.loading = false;
        */
    },
    async getInstituciones(){
        const response      = await this.$api.get("/api/instituciones")
        let {resultSet}     = response.data;
        this.item_escuelas  = resultSet;
    }


},    //methods
    mounted(){
         this.getData();
         this.getInstituciones();
         this.getParametrizacion();
    },
}
</script>

<style scoped>



</style>
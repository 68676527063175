import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
//import Dashboard from '../views/Dashboard.vue'
//import Login from '../views/Login.vue'

import Solicitud from "../views/Solicitud.vue";
import Cursos from "../views/Cursos.vue";




import Directores from "@/views/Directores";
import ResetPassMasivo from "@/views/ResetPassMasivo";


import CuentasUsuario from "@/views/CuentasUsuario/CuentasUsuario";
import PanelAdministradores from "@/views/CuentasUsuario/PanelAdministradores";


import RegistroEscuelas from "@/views/Ticketera/RegistroEscuelas";
import Parametrizacion from "@/views/Ticketera/Parametrizacion";
import AnalizaAccesos from "@/views/Ticketera/AnalizaAccesos";
import ImportarEscuelas from "@/views/Ticketera/ImportarEscuelas";

import NvoLeonCuentas from '@/views/NvoLeon/Index';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  }, 
  {
    path: "/importar-cuentas-nvoleon",
    name: "Importar Cuentas Nvo Leon",
    component: NvoLeonCuentas,
  },
  {
    path: "/importar-escuelas",
    name: "Importar Escuelas",
    component: ImportarEscuelas,
  },
  {
    path: "/analiza-acceso/:id",
    name: "Registro Admins",
    component: AnalizaAccesos,
  },


  {
    path: "/parametrizacion",
    name: "Parametrizacion",
    component: Parametrizacion,
  },
  {
    path: "/RegistroEscuelas",
    name: "Registro Escuelas",
    component: RegistroEscuelas,
  },

  {
    path: "/solicitud",
    name: "Solicitud",
    component: Solicitud,
  },
  {
    path: "/cursos",
    name: "Cursos",
    component: Cursos,
  },


  {
    path: "/directores",
    name: "directores",
    component: Directores,
  },
  {
    path: "/reset-masivo",
    name: "ResetPassMasivo",
    component: ResetPassMasivo,
  },
  {
    path: "/cuentas-usuario",
    name: "CuentasUsuario",
    component: CuentasUsuario,
  },
  {
    path: "/panel-administrador",
    name: "PanelAdministradores",
    component: PanelAdministradores,
 }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>

<v-row>
    <v-col :cols="12">
        <v-subheader>Cambio de contraseñas</v-subheader>
        <br>
    </v-col>
    
 <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          ¡Es necesario elegir <br> una opción!
        </v-card-title>
        
        <v-card-actions>
          <v-spacer></v-spacer>
         <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

 <v-dialog
      v-model="dialog2"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Contraseña <br> reestablecida <br> con éxito
        </v-card-title>
        
        <v-card-actions>
          <v-spacer></v-spacer>
         <v-btn
            color="green darken-1"
            text
            @click="dialog2 = false"
          >
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col :cols="8">
        <download-excel         :data="alumnos"
        worksheet="alumnosInactivos"
        name="alumnosInactivos.xls"
        type="csv"
        >
        <v-btn
        color="success"
        elevation="5"
        >
        <v-icon>
            mdi-file-excel 
        </v-icon>
           Descarga de todas las cuentas de correo
        </v-btn>
        </download-excel>

        <download-excel class="mt-4"
        :data="selected"
        worksheet="alumnosInactivos"
        name="alumnosInactivos.xls"
        type="csv"
        >
        <v-btn
        color="success"
        elevation="5"
        >
        <v-icon>
            mdi-file-excel 
        </v-icon>
            Descargar Seleccionados
        </v-btn>
        </download-excel>
    </v-col>
    <v-col :cols="4">
    <router-link style="text-decoration: none; color: inherit;"  
    to="/reset-masivo">
      <v-btn  color="secondary"
              elevation="5"
              >
             <v-subheader style="color:white; font-weight: 500;">Reestablecimiento masivo</v-subheader>
      </v-btn>
    </router-link>
        <v-btn class="mt-3"
        @click="resetPass"
        color="secondary"
        elevation="5"
        >Reestablecer contraseña
        </v-btn>
    </v-col>
    
    <v-col :cols="12">
        <v-alert v-model="alert" type="success" dismissible>
            ¡Acción en proceso!
        </v-alert>
            <v-alert
              dense
              type="info"
              outlined
            >
        <small> A continuación se muestran todos los datos proporcionados 
        por el administrador del dominio durante la creación de las cuentas</small>
        </v-alert>
        <v-card>
        <v-toolbar flat color="white">  
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
                ></v-text-field>
    </v-toolbar>

    </v-card>
<br>
    <v-data-table
        v-model="selected"
        :search="search"
        :headers="headers"
        :items="alumnos"
        single-select
        item-key="email"
        show-select
        :items-per-page="7"
        class="elevation-1"
        :loading="loading"
        loading-text="Cargando..."
    >
    </v-data-table>
    </v-col>
</v-row>
</template>

<script>
export default {
    name: "Directores",
    data(){
        return{
             dialog2:false,
             dialog: false,
            singleSelect: true,
            selected: [],
            search: '',
            loading: false,
            alert: false,
            alumnos: [],
            headers: [
                    {
                        text: 'Id',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    {
                        text: 'Nombre',
                        sortable: true,
                        value: 'nombreCompleto',
                    },
                    {   text: 'Correo',   
                        sortable: true, 
                        value: 'email' 
                    },  
            ],
        }//return
    }//data
    ,mounted(){
        this.getAlumnos();
    }//mounted
    ,methods:{
    getAlumnos(){
            this.loading = true;
            this.$api.get("/api/cuentas-importadas")
                .then((response) => {
                   // console.log(response.data)
                    this.alumnos = response.data;        
                })
            this.loading = false;
        },
        resetPass(){
            if(this.selected.length === 0){
                this.dialog = true;
                return
            }
            if(!(this.selected.length === 0)){
                this.loading = true;
                this.selected.map( function(elem){
                    console.log("front item "+ elem.id + elem.email);
                    this.$api.post('/api/cuentas-actualizada', {
                        email : elem.email,
                        id : elem.id
                    })
                     .then((response) => {
                        console.log(response.data)
                           this.dialog2 = true;
                     }) 
                     .catch(function (error) {
                        console.log(error)
                    })
                },this);
                this.loading = false;
            }
            return;
        }//resetPass            
    },//methods
};
</script>



<style scoped>
</style>
<template>
  <v-container>

    <div>
<!--      <h1></h1>-->
      <v-subheader>Cuentas de usuarios</v-subheader>
<!--      <v-divider class="mt-5 mb-5"></v-divider>-->
      <v-btn @click="showImportar" class="mb-5 primary white--text">Importar cuentas</v-btn>

      <!--  exportar -->
      <v-dialog
          v-if="dialogExportar" :value="true" @input="dialogExportar = false" persistent max-width="790"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Informe de Errores</span>

          </v-card-title>
          <v-row
              align="center"
              justify="space-around"
              title="Exportar informe"
          >
            <v-btn small title="Exportar informe a hoja de cálculo">
              <a target="_blank" :href="url_log" title="Exportar informe a hoja de cálculo" style="text-decoration: none;">
                <img style="cursor:pointer;" src="google-sheets.png" alt="">
              </a>
            </v-btn>
          </v-row>
          <v-card-text>
            <v-container>
              <v-data-table
                  class="elevation-1"
                  :headers="headers"
                  :items="log_errores"
              >
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialogExportar = false"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--  exportar -->



      <v-dialog
          v-if="dialogImportar" :value="true" @input="dialogImportar = false" persistent max-width="790"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Importación de usuarios</span>
          </v-card-title>
          <v-card-text>
            <v-alert
                dense
                type="info"
            >
              Usted debe de subir su documento <strong>CSV</strong> a su Google Drive, hacerlo público y pegar el link del vínculo en este formulario. Descargue la plantilla
              <a target="_blank" class="white--text" href="https://drive.google.com/file/d/1zp80PC4wcHf0xhDJUcIZAcV1Cx-1PszI/view?usp=sharing">aquí</a>
            </v-alert>
            <div>
              <div class="text-right mb-4">
                <v-btn color="primary" @click="dialogAyuda = !dialogAyuda" small><v-icon small>mdi-help</v-icon></v-btn>
              </div>
              <v-alert :value="dialogAyuda" color="primary" dark border="top" icon="mdi-home" transition="scale-transition">
                <p>Para importar las cuentas debe seguir el siguiente procedimiento:</p>
                <p>1. Una vez descargado el archivo, no debe cambiarle la extension debe conservarse como .csv</p>
                <p>2. Llene así corresponda las columnas Nombre 1, Nombre 2, Apellido 1, Apellido 2.</p>
                <p>3. Clave de identificación: coloque la clave DNI que (dato numérico) que identifica de manera única a cada ciudadano.</p>
                <p>4. En la columna Tipo de cuenta colocar, UNICAMENTE la letra que así corresponda, dependiendo del tipo de usuario a crear su cuenta: </p>
                <b>E</b> Estudiante o alumno<br>
                <b>P</b> Profesor o Docente<br>
                <b>D</b> Director<br>
                <b>A</b> Administrativo<br><br>
                <p>5. En las Columnas Nombre DRE, Nombre UGEL y Nombre IE, coloque los datos correspondientes.</p>
                <p>6. En la columna Código modular, capture el código que identifica de manera única al Colegio, Escuela o Centro educativo.</p>
                <p>7. En la columna Unidad Organizativa, capture la unidad organizativa en donde se ubicará la cuenta a crear, por ejemplo: <b>ALUMNO/DRE/UGEL</b></p>
                <p>8. Una vez que haya concluido la captura de los datos correspondientes, y guardado en formato .csv, debe subirlo a su cuenta google drive y generar la ruta para compartirlo.</p>
<!--                <p>9. El vínculo que se genera para compartir el archivo, deberá colocarle la propiedad "Cualquier persona que tenga el vínculo ", después copiar el vínculo y pegarlo en el campo ubicado abajo denominado "Ruta del archivo", debe tener la siguiente estructura de ejemplo: <b>https://drive.google.com/file/d/1kFL0pxO1ja7L2lsjKb-P_r8wW-ZbiE11/view?usp=sharing</b></p>-->
                <p>NOTA: Por archivo .csv le permite subir un total  <b>1,048,575</b> registros</p>
              </v-alert>
            </div>

            <template>
              <v-img src="https://i.imgur.com/R5O9AU4.png"></v-img>
              <v-text-field v-model="nombre_documento" label="Nombre de la Importación"></v-text-field>
              <v-text-field v-model="archivo" label="Ruta del archivo"></v-text-field>
              <v-progress-circular
                  :size="50"
                  v-if="loadingDocument"
                  color="primary"
                  indeterminate
              ></v-progress-circular>
              <v-btn v-if="!loadingDocument" @click="upload" class="primary">Subir Archivo</v-btn>
              <v-divider class="mt-5 mb-5"></v-divider>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialogImportar = false"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-data-table
          class="elevation-1"
          :loading="loading"
          :headers="headersImportaciones"
          :items="importaciones"
          :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn icon color="grey" @click="actualizar()">
              <v-icon>mdi-cached</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.estatus_importacion="{ item }">
<!--            <div v-if="item.estatus_importacion==1"><v-icon small color="orange" title="No Importado">mdi-checkbox-multiple-blank-outline</v-icon></div>-->
            <div v-if="item.estatus_importacion==1">En proceso de importacion</div>
            <div v-if="item.estatus_importacion==2">En proceso de validación</div>
            <div v-if="item.estatus_importacion==3">En creación de cuentas</div>
            <div v-if="item.estatus_importacion==4">Importación concluida</div>
        </template>
        <template v-slot:item.terminado="{ item }">
          <!--            <div v-if="item.estatus_importacion==1"><v-icon small color="orange" title="No Importado">mdi-checkbox-multiple-blank-outline</v-icon></div>-->
          <div v-if="item.terminado==0">En proceso</div>
          <div v-if="item.terminado==1">Terminado</div>
        </template>
        <template v-slot:item.consultar="{ item }">
          <v-btn
              fab elevation="2"
              color="green"
              small
              x-small
              @click="consultarLog(item)"
              title="Ver log de Errores"
              style="margin-right: 10px"
          >
            <v-icon small color="white"  >mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>
<script>
export default {
  data:() => ({
    habilitar_boton : true,
    detalles_importacion : '',
    loadingImportar : true,
    dialogUsuario : false,
    dialogImportar : false,
    dialogExportar : false,
    loading : true,
    loadingDocument : false,
    log_errores : [],
    headersImportaciones : [
        {text : 'Nombre documento', sortable: false, value: 'nombre_documento_importado'},
        {text : 'Estatus del trabajo', sortable: false, value: 'estatus_importacion'},
        {text : 'Documento', sortable : false, value : 'url'},
        {text : 'Cuentas creadas', sortable : false, value : 'cuentas_importadas_exitosamente', align:'center'},
        {text : 'Cuenta pre existente en consola', sortable : false, value : 'cuentas_ingresadas_usuario', align:'center'},
        {text : 'Registros repetidos', sortable : false, value : 'cuentas_repetidas', align:'center'},
        {text : 'Registros con error', sortable : false, value : 'cuentas_error', align:'center'},
        {text : 'Procesadas', sortable : false, value : 'procesadas', align:'center'},
        {text : 'Total registros ', sortable : false, value : 'total_registros', align:'center'},
       // {text : 'Terminado ', sortable : false, value : 'terminado', align:'center'},
        { text: 'Acciones',   value: 'consultar',  align:'center'},
        //{text : 'Importar', sortable : false, value : 'importar_documento'},
        //{text : 'Eliminar', sortable : false, value : 'eliminar_documento'}
    ],
    headers : [
      {text : 'Número de fila', sortable: false, value: 'numero_fila'},
      {text : 'Descripción', sortable: false, value: 'descripcion_error'},
      //{text : 'Importar', sortable : false, value : 'importar_documento'},
      //{text : 'Eliminar', sortable : false, value : 'eliminar_documento'}
    ],
    importaciones : [],
    dialogAyuda:false,
    nombre_documento:'',
    archivo:[],
    filename: '',
    file: '',
    url_log:'',
    search:''
  }),

  methods : {
    actualizar(){
      this.getImportaciones();
    },
    getImportaciones(){
        this.loading = true;
        this.$api.get("/api/cuentas-usuarios").then((response) => {
            //console.log("importaciones ----->")
            //console.log(response.data)
            this.importaciones = response.data;
            this.loading = false;
        });
    },
    closeAyuda(){
      this.dialogAyuda =  false
    },
    getSheets(){

    },
    prepara(e){
      this.filename = "Selected File: " + e.target.archivo[0].name;
      this.file = e.target.archivo[0];
    },
    setVariables(){
      this.nombre_documento = '';
      this.archivo          = '';
    },
    upload(){
      this.loadingDocument = true;
        this.$api.post('/api/cuentas-usuarios', {
          'nombre_documento':        this.nombre_documento,
          'archivo':                 this.archivo,
        })
        .then(() => {
          alert('La importacion esta en proceso...')

          this.setVariables();
          this.getImportaciones();
          this.loadingDocument = false;
          })
          .catch(function (error) {
              console.log(error)

          })
    },
    showAyuda() {
      this.dialogAyuda = true;
    },
    consultarLog(item){
      this.dialogExportar = true;
      this.url_log  = item.url_log;
      this.$api.get(`/api/log-errores/${item.id}`).then((response) => {
        //console.log(response.data)
        this.log_errores = response.data;
        this.loading = false;
      });
    },
    showImportar() {
      this.dialogImportar = true;
    },
    showPickerDialog(){
      this.onApiLoad()
    },
  },
  mounted() {
    this.getImportaciones();
  },
}
</script>
<template>
  <v-container fluid>
                      <v-snackbar
                                      v-model="snackbar"
                                      :multi-line="multiLine"
                                      :color="colorSnackbar"
                                      :timeout="timeout"
                                  >
                                  {{textoSnackbar}}
                                  <template v-slot:action="{ attrs }">
                                      <v-btn
                                      color="white"
                                      text
                                      v-bind="attrs"
                                      @click="snackbar = false"
                                      >
                                      Cerrar
                                      </v-btn>
                                  </template>
                              </v-snackbar>
        <v-row>
            <v-col :cols="12">   
                 <v-card-title>
                    <v-icon large class="mr-2" color="purple">
                        mdi mdi-account-multiple
                    </v-icon>
                    <h3>Importar cuentas Nuevo Lion</h3>

                </v-card-title>
                <div style="margin-left:50px" >
                    <router-link style="text-decoration: none; color: inherit;" to="/registroEscuelas">
                        Volver
                    </router-link>
                </div>                
            </v-col>


        </v-row>
        <v-row>
            <v-col :cols="8">   
                <v-card-text>

                <v-text-field ref="nombreURL" required :rules="rulesss" v-model="archivo" label="Ruta del archivo"></v-text-field>
                <br>
                          <v-btn 
                                 color="primary"
                                 dark
                                 @click="upload"
                                 class="primary my-2 ml-auto "
                                >Procesar Plantilla
                          </v-btn>
                    
                </v-card-text>
            </v-col>

        </v-row>


  </v-container>
</template>

<script>
export default {
    data() {
        return {
          sheetId:null,
        timeout:2500,
        snackbar:false,
        textoSnackbar:"",
        colorSnackbar:"",
        multiLine: true,
        rulesss: [
                v => !!v || 'Dato requerido',
                v => this.isURL(v) || v.length >=44 || 'No es una URL',
            ],
          archivo:[],
          dialogAyuda:false,
          loadingDocument : false,
          dialogImportar : false,
        }
    },methods: {
    
    isURL(str) {
      let url;

      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },

    resetFields(){
        this.archivo='';        
    },

    upload(){

        let file  = (this.archivo) ? this.archivo : 0;
        if(file==0 ) { 

                this.textoSnackbar  = "El campo URL es necesario";
                this.colorSnackbar  = "deep-orange darken-3";
                this.snackbar       = true;
                this.$refs.nombreURL.focus();
            return 
            }

         this.$api.post('/api/data-import/nvoleon/cuentas', {
                'archivo': this.archivo
          })
          .then( (response) => {
             
            this.resetFields();
               let { message
                    , color
                } = response.data;
                
                this.textoSnackbar  = message;
                this.colorSnackbar  = color;
                this.snackbar       = true;
          })
          .catch(function (e) {
                this.textoSnackbar  = "Ocurrió un error general, favor de comunicarse con su administrador: " + e.name + ': ' + e.message;
                this.colorSnackbar  = "deep-orange darken-3";
                this.snackbar       = true;

          })


    }

    },
}
</script>

<style>

</style>
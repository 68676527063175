import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#952f81',
            secondary: '#64c3d1',
            accent: '#f9d94c',
            error: '#b71c1c',
          },
        },
      },
      lang: {
        locales: {es},
        current: 'es',
      }
});

import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import DateFilter from "./filters/date";
import DateIsoFilter from "./filters/isoDate";
import JsonExcel from "vue-json-excel";

// @ is an alias to /src
import axios from "axios";

const API_BASE_URL = process.env.VUE_APP_API_URL;
Vue.use({
  install(Vue) {
    Vue.prototype.$api = axios.create({
      baseURL: API_BASE_URL,
      withCredentials: true,
    });
    Vue.prototype.$api.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (error.response.status === 401 || error.response.status === 419) {
          Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
            window.location = `${API_BASE_URL}/redirect`;
          });
        }
        if (error.response.status === 403) {
          window.location = process.env.VUE_APP_URL;
        }
        return Promise.reject(error);
      }
    );
  },
});

Vue.config.productionTip = false;

Vue.filter("date", DateFilter);
Vue.filter("isoDate", DateIsoFilter);
Vue.component("downloadExcel", JsonExcel);

new Vue({
  router,
  store,
  vuetify,
  JsonExcel,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <v-row>
    <v-col :cols="12">
      <v-subheader>Cursos</v-subheader>
    </v-col>
    <v-col :cols="12">
      <v-alert v-model="alert" dismissible type="success">
        Cursos copiando, se notificara via correo cuando termine.
      </v-alert>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="cursos"
        :loading="loading"
        :search="search"
        :single-select="singleSelect"
        class="elevation-1"
        item-key="id"
        loading-text="Cargando..."
        show-select
      >
        <template v-slot:top>
          <v-toolbar color="white" flat>
            <!--<v-toolbar-title>Cursos</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>-->
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              hide-details
              label="Buscar"
              single-line
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  class="mb-2"
                  color="primary"
                  dark
                  @click="copyItem()"
                  >Copiar</v-btn
                >
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="12" sm="12">
                        <v-text-field
                          v-model="copiedItem.name"
                          label="Nombre"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" sm="12">
                        <v-text-field
                          v-model="copiedItem.description"
                          label="Descripcion"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" sm="12">
                        <v-text-field
                          v-model="cantidad"
                          label="Cantidad de cursos a copiar"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <!--<v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.name" label="Dessert name"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.calories" label="Calories"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.fat" label="Fat (g)"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.carbs" label="Carbs (g)"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.protein" label="Protein (g)"></v-text-field>
                  </v-col>-->
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="close">Cancelar</v-btn>
                  <v-btn color="primary" text @click="copiarCursos"
                    >Crear</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.alternateLink="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                :disabled="!item.alternateLink"
                class="mr-2"
                color="orange"
                @click="abrirCurso(item.alternateLink)"
              >
                mdi-google-classroom
              </v-icon></template
            >
            <span>Ver Curso</span>
          </v-tooltip>
        </template>
        <template v-slot:item.creationTime="{ item }">
          {{ item.creationTime | date }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Cursos",
  data() {
    return {
      search: "",
      alert: false,
      loading: false,
      dialog: false,
      singleSelect: true,
      copyDisabled: "disabled",
      cantidad: 0,
      selected: [],
      headers: [
        {
          text: "Curso",
          align: "start",
          //sortable: false,
          value: "name",
        },
        { text: "Descripcion", value: "description" },
        { text: "Estado", value: "courseState" },
        { text: "Creado", value: "creationTime" },
        { text: "Acciones", value: "alternateLink", align: "center" },
      ],
      cursos: [],
      copiedItem: {},
    };
  },
  computed: {
    msg() {
      const selectedRow = this.selected[0];
      return selectedRow ? `${selectedRow.name} ${selectedRow.id}` : "";
    },
    formTitle() {
      const selectedRow = this.selected[0];
      return selectedRow ? `${selectedRow.name}` : "";
    },
  },
  mounted() {
    this.getCursos();
  },
  methods: {
    getCursos() {
      this.loading = true;
      this.$api.get("/api/cursos").then((response) => {
        //console.log(response.data)
        this.cursos = response.data;
        this.loading = false;
      });
    },
    abrirCurso(liga) {
      window.open(liga, "_blank");
    },
    close() {
      this.dialog = false;
      this.selected = [];
      this.cantidad = 0;
      this.$nextTick(() => {
        this.copiedItem = Object.assign({});
        //this.editedIndex = -1
      });
    },
    copyItem() {
      if (this.selected.length > 0) {
        Object.assign(this.copiedItem, this.selected[0]);
        this.cantidad = 0;
        this.dialog = true;
      }
    },
    copiarCursos() {
      this.$api
        .post("/api/cursos", {
          id: this.copiedItem.id,
          name: this.copiedItem.name,
          description: this.copiedItem.description,
          cantidad: this.cantidad,
        })
        .then((response) => {
          //console.log(response.data)
          if (response.data.success) {
            this.alert = true;
            this.hideAlert();
            this.dialog = false;
            this.selected = [];
            this.cantidad = 0;
            this.$nextTick(() => {
              this.copiedItem = Object.assign({});
              //this.editedIndex = -1
            });
          }
          //this.cursos = response.data
          //this.loading = false
        });
    },
    hideAlert() {
      setTimeout(() => {
        this.alert = false;
      }, 3000);
    },
  },
};
</script>

<style></style>

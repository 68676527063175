<template>
  <v-container fluid>
    <v-row>
        <v-col :cols="12">         

                     <v-card elevation="4">
                        <v-card-title>
                            <v-icon large class="mr-2" color="purple">
                                mdi mdi-account-multiple
                            </v-icon>
                            <h3>Importar escuelas</h3>

                        </v-card-title>
                            <div style="margin-left:50px" >
                              <router-link style="text-decoration: none; color: inherit;" to="/registroEscuelas">
                                    Volver
                              </router-link>
                            </div>
                        <v-card-text>

                          <v-row>
                           
                            <v-card-text>



                              <v-alert
                                  dense
                                  type="info"
                              >
                               Se debe de utilizar un documento <strong>google spreadsheet</strong> desde su Google Drive, hacerlo público y pegar el link del vínculo en este formulario. Tomar de referencia la plantilla
                              
                             <button @click="createSheet()"> <b> <u>__AQUI__</u></b></button>
                              </v-alert>       

                              <v-snackbar
                                      v-model="snackbar"
                                      :multi-line="multiLine"
                                      :color="colorSnackbar"
                                      :timeout="timeout"
                                  >
                                  {{textoSnackbar}}
                                  <template v-slot:action="{ attrs }">
                                      <v-btn
                                      color="white"
                                      text
                                      v-bind="attrs"
                                      @click="snackbar = false"
                                      >
                                      Cerrar
                                      </v-btn>
                                  </template>
                              </v-snackbar>


                              <div>
                                <div class="text-right">
                                  <v-btn color="primary" @click="dialogAyuda = !dialogAyuda" small><v-icon small>mdi-help</v-icon></v-btn>
                                </div>

                                <v-alert :value="dialogAyuda" color="primary" dark border="top" icon="mdi-home" transition="scale-transition">
                                  <p>Para importar ESCUELAS debe seguir el siguiente procedimiento:</p>
                                  <p>1. Una vez creado el archivo google spreadsheet, no debe cambiarle la extension</p>
                                  <p>2. Llene así corresponda las columnas nombre de la escuela, clave del centro escolar, domicilio y el tipo de institución de que se trata. NOTA: en institución deberá escribir exactamente ya sea Pública o Privada, tal cual.</p>
                                  <p>3. En los campos de acceso Aula y Analiza, se deberán poner los correos de los docentes que tendrán acceso a la herramienta en cuestión. </p>
                                  <p>4. Una vez que haya concluido la captura de los datos correspondientes, desde su cuenta google drive se debe generar la ruta para compartirlo.</p>
                                </v-alert>
                              </div>

                              <template>
                              <!--  <v-img width="800px" height="auto" src="https://i.imgur.com/LFU2PjN.png"></v-img> -->
                              <v-img width="800px" height="auto" src="https://storage.googleapis.com/estudiolab-v1/asigna-imgs/subirArchivo.png"></v-img> 
                                <br>
                        <v-card-title>
                           <v-icon large class="mr-2" color="purple" style="left:20px">
                                mdi mdi-arrow-down-thick
                            </v-icon>
                             <v-icon large class="mr-2" color="purple" style="left:5px; right:20px">
                                mdi mdi-arrow-down-thick
                            </v-icon>
                            <h3>Colocar aquí la liga del spreadsheet</h3>
                            <v-icon large class="mr-2" color="purple" style="left:20px">
                                mdi mdi-arrow-down-thick
                            </v-icon>
                             <v-icon large class="mr-2" color="purple" style="left:5px">
                                mdi mdi-arrow-down-thick
                            </v-icon>
                        </v-card-title>
                                <br>
                                <v-text-field ref="nombreURL" required :rules="rulesss" v-model="archivo" label="Ruta del archivo"></v-text-field>
                                <v-progress-circular
                                    :size="50"
                                    v-if="loadingDocument"
                                    color="primary"
                                    indeterminate
                                ></v-progress-circular>
                              </template>

                            </v-card-text>
  
                          </v-row>
                          <v-btn v-if="!loadingDocument"
                                 color="primary"
                                 dark
                                 @click="upload"
                                 class="primary my-2 ml-auto "
                                >Procesar Plantilla
                          </v-btn>
                        </v-card-text>
                    </v-card>
                    
        </v-col>

   </v-row>
  

  </v-container>

</template>

<script>
export default {
    data() {
        return {
          sheetId:null,
        timeout:2500,
        snackbar:false,
        textoSnackbar:"",
        colorSnackbar:"",
        multiLine: true,
        rulesss: [
                v => !!v || 'Dato requerido',
                v => this.isURL(v) || v.length >=44 || 'No es una URL',
            ],
          archivo:[],
          dialogAyuda:false,
          loadingDocument : false,
          dialogImportar : false,
        }
    },
     computed: {

    },

    watch: {

    },

  methods: {
    createSheet(){

          this.$api.post('/api/escuelas-prepareSheet')
          .then( (response) => {

            let { message
                , id
                , color
                , url_2
                , success
                } = response.data;
                
                this.sheetId        = id;
                this.textoSnackbar  = message;
                this.colorSnackbar  = color;
                this.snackbar       = true;
              if(success){
                window.open(url_2); 
              }        
          })
          .catch(function (e) {
                this.textoSnackbar  = "Ocurrió un error general, favor de comunicarse con su administrador: " + e.name + ': ' + e.message;
                this.colorSnackbar  = "deep-orange darken-3";
                this.snackbar       = true;
          })

    }//createSheet
    
    
    ,isURL(str) {
      let url;

      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    },
    save(){
        console.log("OK")
    },
    setVariables(){
          this.archivo          = '';
    },
    showImportar() {
      this.dialogImportar = true;
    },
    upload(){

        let file  = (this.archivo) ? this.archivo : 0;
        if(file==0 ) { 

                this.textoSnackbar  = "El campo URL es necesario";
                this.colorSnackbar  = "deep-orange darken-3";
                this.snackbar       = true;
                this.$refs.nombreURL.focus();
            return 
            }

      this.$api.post('/api/data-import', {
        //  this.$api.post('/api/escuelas-importacion', {
                'archivo': this.archivo
                ,'id'     :this.sheetId
          })
          .then( (response) => {
             
            this.setVariables();
               let { message
                , color
                } = response.data;
                
                this.textoSnackbar  = message;
                this.colorSnackbar  = color;
                this.snackbar       = true;
          })
          .catch(function (e) {
                this.textoSnackbar  = "Ocurrió un error general, favor de comunicarse con su administrador: " + e.name + ': ' + e.message;
                this.colorSnackbar  = "deep-orange darken-3";
                this.snackbar       = true;

          })


    }

  }

}
</script>

<style scoped>

</style>